import React from 'react';
import {useSelector} from 'react-redux';
import Button from '../common/form/Button';
import {memberElectionSelector} from '../../redux/selectors/MemberElectionSelector';
import {childRelationships} from '../../properties/relationships';
import {
    hasChildDependent,
    hasSpouseDependent,
    NON_WAIVABLE_COVERAGE_MESSAGE,
    WAIVABLE_COVERAGE_MESSAGE
} from './ElectionUtil';
import CurrencyUtil from '../../utils/currencyUtil';
import CoverageTableHeader from '../common/header/CoverageTableHeader';
import {useTieredCoverageRemover} from '../../hook/remover/useTieredCoverageRemover';
import {hospitalIndemnityRateSelector} from '../../redux/selectors/HospitalIndemnityRateSelector';
import {useHospitalIndemnityCoverageDispatcher} from '../../hook/dispatch/useHospitalIndemnityCoverageDispatcher';
import {VOLUNTARY_HOSPITAL_INDEMNITY_SORT_CODES} from '../../properties/coverages';
import {getVHOSDisplayName} from '../../utils/hosUtil';
import {issuerStateSelector} from '../../redux/selectors/IssuerStateSelector';

const HospitalIndemnityElection = () => {
    const hospitalIndemnityCoverages = useSelector(hospitalIndemnityRateSelector);
    const memberElection = useSelector(memberElectionSelector);
    const dispatchHospitalIndemnityCoverage = useHospitalIndemnityCoverageDispatcher();
    const removeHospitalIndemnityCoverage = useTieredCoverageRemover();
    const issuerState = useSelector(issuerStateSelector);

    const buildSummary = () =>
        <div>
            {issuerState === 'Colorado' && buildLegalBlurbCO()}
            {(issuerState === 'Colorado' || issuerState === 'Pennsylvania') && buildLegalBlurbFederal()}
            {buildSummaryList()}
        </div>;

    const buildLegalBlurbFederal = () =>
        <div style={{fontSize: '14pt', marginBottom: '20px'}} id={'hospitalIndemnityCoverageLegalBlurb'}>
            <div>Federal Fixed Indemnity Notice</div>
            <div style={{border: '1px solid #000000', padding: '10px 20px'}}>
                <p style={{textAlign: 'center'}}><strong>IMPORTANT: This is a fixed indemnity policy, NOT health
                    insurance</strong></p>
                <span>This fixed indemnity policy may pay you a limited dollar amount if you’re sick or hospitalized. You’re
                still responsible for paying the cost of your care.</span>
                <ul>
                    <li>The payment you get isn’t based on the size of your medical bill.</li>
                    <li>There might be a limit on how much this policy will pay each year.</li>
                    <li>This policy isn’t a substitute for comprehensive health insurance.</li>
                    <li>Since this policy isn’t health insurance, it doesn’t have to include most Federal consumer
                        protections that apply to health insurance.
                    </li>
                </ul>
                <span><strong>Looking for comprehensive health insurance?</strong></span>
                <ul>
                    <li><strong>Visit HealthCare.gov</strong> or call <strong>1-800-318-2596</strong> (TTY:
                        1-855-889-4325)
                        to find health coverage options.
                    </li>
                    <li>To find out if you can get health insurance through your job, or a family
                        member’s job, contact the employer.
                    </li>
                </ul>
                <span><strong>Questions about this policy?</strong></span>
                <ul>
                    <li>For questions or complaints about this policy, contact your State
                        Department of Insurance. Find their number on the National Association of
                        Insurance Commissioners’ website (naic.org) under “Insurance
                        Departments.”
                    </li>
                    <li>If you have this policy through your job, or a family member’s job, contact
                        the employer.
                    </li>
                </ul>
            </div>
        </div>;

    const buildLegalBlurbCO = () =>
        <div style={{fontSize: '14pt', marginBottom: '20px'}} id={'hospitalIndemnityCoverageLegalBlurbCO'}>
            <div>Colorado Fixed Indemnity Notice</div>
            <div style={{border: '1px solid #000000', padding: '10px 20px'}}>
                <p style={{textAlign: 'center'}}><strong>IMPORTANT: This is a fixed indemnity policy, NOT health
                    insurance</strong></p>
                <span>This fixed indemnity policy may pay you a limited dollar amount if you’re sick or
                hospitalized. You’re still responsible for paying the cost of your care.</span>
                <ul>
                    <li>The payment you get isn’t based on the size of your medical bill.</li>
                    <li>There might be a limit on how much this policy will pay each year.</li>
                    <li>This policy isn’t a substitute for comprehensive health insurance.</li>
                    <li>Since this policy isn’t health insurance, it doesn’t have to include
                        most Federal consumer protections that apply to health insurance.
                    </li>
                </ul>
                <span><strong>Looking for comprehensive health insurance?</strong></span>
                <ul>
                    <li><strong>Visit Connectforhealthco.com</strong> or call <strong>1-855-752-6749 </strong> (TTY:
                        1-855-695-5935)
                        to find health coverage options.
                    </li>
                    <li>To find out if you can get health insurance through your employer,
                        or a family member’s employer, contact the employer.
                    </li>
                </ul>
                <span><strong>Questions about this policy?</strong></span>
                <ul>
                    <li>For questions or complaints about this policy, contact Colorado
                        Division of Insurance, Customer Services Team, 303-894-7490, 800-
                        930-3745.
                    </li>
                    <li>To find out if you can get health insurance through your employer,
                        or a family member’s employer, contact the employer.
                    </li>
                </ul>
            </div>
        </div>;

    const buildSummaryList = () =>
        <ul style={{paddingLeft: '0'}}>
            <li>{`${hospitalIndemnityCoverages[0].product.productType} insurance provides additional financial protection when unplanned ` + 'medical expenses occur. This allows you to focus on medical recovery, and not the financial burden' + 'from medical bills.'}
            </li>
            <li>{`${hospitalIndemnityCoverages[0].product.productType} is a supplemental benefit and not a substitute for major medical coverage.`}</li>
            <li>Benefits are paid regardless of other insurance coverage or actual expenses incurred.</li>
            <li>Pays cash directly to you, and you can choose to spend the money however you would like.</li>
            <li>No evidence of insurability is required to purchase this product.</li>
            <li>Includes a health screening benefit to encourage preventative care.</li>
        </ul>;

    const buildCoverageSummary = (coverage) => {
        return <div className='col-md-12' id={`hospitalIndemnityCoverage-summary${coverage.esclKey}`}>
            <br/>
            {hospitalIndemnityCoverages.length > 1 && <h3 id={`hospitalIndemnityCoverage-header${coverage.esclKey}`}
                                                          style={{marginTop: '0'}}>{getCoverageHeader(coverage)}</h3>}

            {hospitalIndemnityCoverages.length === 1 && buildSummary()}
        </div>;
    };

    const buildRow = (rate, coverage, index) => {
        const activeTier = isActiveTier(coverage, rate);
        return <tr key={`${rate.tier}-row-${index}`} id={`${rate.tier}-row`}
                   className={activeTier ? 'bg-light-blue' : ''}>
            <td id={`${rate.tier}-displayName-${index}`}>{rate.displayName}</td>
            <td id={`${rate.tier}-rate`}>{CurrencyUtil.formatUSD(rate.rate)}</td>
            <td>
                <Button
                    id={`${rate.tier}-coverageButton-${index}`}
                    clickText={activeTier ? 'Remove' : 'Add'}
                    style={{fontSize: '13px', minWidth: '140px'}}
                    default={activeTier}
                    onClick={() => {
                        activeTier
                            ? removeHospitalIndemnityCoverage(coverage, 'hospitalIndemnityBenefitExclusionKey')
                            : dispatchHospitalIndemnityCoverage(rate, coverage);
                    }}
                >
                    <i style={{paddingRight: '5px'}} className={activeTier ? 'fa fa-trash-o' : 'fa fa-shopping-cart'}/>
                    {activeTier ? 'Remove' : 'Add'}
                </Button>
            </td>
        </tr>;
    };

    const buildTable = (coverage) => {
        return <div key={`hospitalindemnitycoverage-rate${coverage.esclKey}`}>
            {buildCoverageSummary(coverage)}
            <div>
                {<table className='table'>
                    <CoverageTableHeader/>
                    {buildTableBody(coverage)}
                </table>}
                <div id='hospitalIndemnityElectionWaveCoverageDiv' className='bg-light-gray'
                     style={{marginBottom: '15px', padding: '8px', textAlign: 'center'}}>
                    {coverage.optional ? WAIVABLE_COVERAGE_MESSAGE : NON_WAIVABLE_COVERAGE_MESSAGE}
                </div>
            </div>
        </div>;
    };

    const buildTableBody = (coverage) => {
        return <tbody>
        {coverage.rates
            .filter(rates => shouldDisplayRow(rates, memberElection.familyDemographics.dependents, coverage.benefitOptions))
            .map((rate, index) => buildRow(rate, coverage, index))}
        </tbody>;
    };

    const hasChildren = (dependents) => {
        return dependents.filter(dependent => childRelationships.includes(dependent.relationship)).length > 1;
    };

    const getCoverageHeader = (coverage) => {
        return VOLUNTARY_HOSPITAL_INDEMNITY_SORT_CODES.includes(coverage.product.compassGroupSortCode)
            ? getVHOSDisplayName(hospitalIndemnityCoverages, coverage)
            : 'Hospital Indemnity';
    };

    const isActiveTier = (coverage, rates) => {
        return memberElection.enrolledCoverages
            && memberElection.enrolledCoverages
                .some(electedCoverage => electedCoverage.esclKey === coverage.esclKey
                    && electedCoverage.tier === rates.tier
                    && electedCoverage.tierDisplayName === rates.displayName);
    };

    const shouldDisplayRow = (rate, dependents, benefitOptions) => {
        if (rate.tier.includes('THREE')) {
            if (rate.displayName === 'You and your family') {
                return hasSpouseDependent(dependents, benefitOptions) && hasChildDependent(dependents) || hasChildren(dependents);
            } else {
                return hasChildDependent(dependents) || hasSpouseDependent(dependents, benefitOptions);
            }
        }
        if (rate.displayName === 'You and your spouse') {
            return hasSpouseDependent(dependents, benefitOptions);
        } else if (rate.displayName === 'You and your children') {
            return hasChildDependent(dependents);
        } else if (rate.displayName === 'You and your family') {
            return hasSpouseDependent(dependents, benefitOptions) && hasChildDependent(dependents)
                || rate.tier === 'TWO_TIER' && (hasSpouseDependent(dependents, benefitOptions) || hasChildDependent(dependents));
        } else {
            return rate.displayName === 'You';
        }
    };

    return <div className='col-md-12'>
        {hospitalIndemnityCoverages.length > 1 &&
            <div className='col-md-12' style={{paddingTop: '25px'}}>{buildSummary()}</div>}
        {hospitalIndemnityCoverages.map(coverage => buildTable(coverage))}
    </div>;
};

export default HospitalIndemnityElection;
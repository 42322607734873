import React from 'react';
import {DefaultDisclosure} from './DefaultDisclosure';
import {CaliforniaDisclosure} from './CaliforniaDisclosure';
import {useSelector} from 'react-redux';
import {issuerStateSelector} from '../../redux/selectors/IssuerStateSelector';

const EConsentDisclosure = () => {
    const issuerState = useSelector(issuerStateSelector);

    return <div className='disclosure-scroll'>
        <h3>Disclosure and Consent Regarding Conducting Business Electronically</h3>
        {issuerState === 'California'
            ? <CaliforniaDisclosure/>
            : <DefaultDisclosure/>}
    </div>;
};

export default EConsentDisclosure;